import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Footer } from '../components/Footer';
import NavBar from '../components/NavBar';
import Version from './Version';
import ErrorView from './ErrorView';


export default function AnonymousContainer() {
  return (
    <Router>
      <NavBar />
        <Switch>
          <Route path='/version' component={Version} />
        </Switch>
      <Footer />
    </Router>
  );
}
