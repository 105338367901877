import { Palette, PaletteOptions, SimplePaletteColorOptions } from '@mui/material/styles/createPalette';

export type MainPaletteKeys = keyof Pick<
PaletteOptions,
'primary'
| 'secondary'
| 'info'
| 'success'
| 'warning'
| 'error'
| 'background'
| 'text'
| 'border'
| 'accent'
| 'placeholder'
>;

export const paletteOpts: Record<MainPaletteKeys, Record<string, string>> = {
  border: {
    dark: '',
    main: '#DAD9DF',
    light: ''
  },
  accent: {
    main: '#420E96',
    light: '#EFF0FF'
  },
  primary: { // accent colors
    main: '#420E96', // Turquoise
    light: '#EFF0FF' // Turquoise light
  },
  secondary: { // CTA colors
    main: '#41A9B3',
    light: '#D7F5F8'
  },
  info: { // blue = referred/waiting (status)
    main: '#2091E2',
    light: '#D8EEFF'
  },
  success: { // green = interested (status)
    main: '#0DB77A',
    light: '#CCF9E9'
  },
  warning: { // yellow = matched (status)
    main: '#FAB402',
    light: '#FFF8E8'
  },
  error: { // red = rejected (status)
    main: '#EA6172',
    light: '#FEE9EB'
  },
  background: {
    paper: '#FFF',
    default: '#F7F8F9'
  },
  text: {
    primary: '#212632', // black text
    secondary: '#5C657C' // grey text
  },
  placeholder: {
    main: '#9896A7'
  }
};
