import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../components/Button';
import { typedKeys } from '../lib/utils';
import { API } from '../api';
import { useUser } from '../contexts/UserContext';
import DatePicker from '@mui/lab/DatePicker';
import { Card, Typography, Box, Container, Grid, CircularProgress, TextField, Divider } from '@mui/material';
import studentPlaceholderSvg from '../assets/studentPlaceholder.svg';
import yesMarkSvg from '../assets/yesMark.svg';
import noMarkSvg from '../assets/noMark.svg';
import searchMarkSvg from '../assets/searchMark.svg';
import notFoundSvg from '../assets/notFoundMark.svg';
import { useAlert } from '../contexts/AlertContext';
import { ConsentScope } from '../types/types';
import ConsentStatusChip from '../components/ConsentStatusChip';
import Dialog from '../components/Dialog';

enum ConsentCheckStatus {
  Unknown = 1,
  ConsentedBehavioralOnly,
  ConsentedPhysicalOnly,
  ConsentedAll,
  OptedOut,
  NotFound,
  MultipleFound,
  Error
}

const imageForCheckResult = (checkResult: ConsentCheckStatus) => {
  switch (checkResult) {
    case ConsentCheckStatus.NotFound:
      return notFoundSvg;
    case ConsentCheckStatus.ConsentedAll:
    case ConsentCheckStatus.ConsentedBehavioralOnly:
    case ConsentCheckStatus.ConsentedPhysicalOnly:
      return yesMarkSvg;
    case ConsentCheckStatus.OptedOut:
      return noMarkSvg;
    case ConsentCheckStatus.MultipleFound:
      return noMarkSvg;
    case ConsentCheckStatus.Error:
      return noMarkSvg;
    default:
      return searchMarkSvg;
  }
};

const messageForCheckResult = (checkResult: ConsentCheckStatus, name?: String) => {
  switch (checkResult) {
    case ConsentCheckStatus.NotFound:
      return (<Typography variant="body1" align="center">Student information not found.</Typography>);
    case ConsentCheckStatus.ConsentedAll:
    case ConsentCheckStatus.ConsentedBehavioralOnly:
    case ConsentCheckStatus.ConsentedPhysicalOnly:
      return (<Typography variant="h3" align="center">{name || 'This student is registered.'}</Typography>);
    case ConsentCheckStatus.OptedOut:
      return (<Typography variant="body1" align="center" color="#D73939">This student has opted out of School Telehealth.</Typography>);
    case ConsentCheckStatus.MultipleFound:
      return (<Typography variant="body1" align="center" color="#D73939">Multiple students found. Please enter more information.</Typography>);
    case ConsentCheckStatus.Error:
      return (<Typography variant="body1" align="center" color="#D73939">Request failed. Try again later.</Typography>);
    default:
      return (
        <Container>
          <Typography variant="body1" align="center">Search for student to check the consent.</Typography>
          <Typography variant="body1" align="center">Results will appear here.</Typography>
        </Container>
      );
  }
};

interface ConsentProps {
  status: ConsentCheckStatus
}

const ConsentChips: React.FC<ConsentProps> = ({ status }) => {
  if (status === ConsentCheckStatus.ConsentedAll || status === ConsentCheckStatus.ConsentedBehavioralOnly || status === ConsentCheckStatus.ConsentedPhysicalOnly) {
    const bhGranted = status === ConsentCheckStatus.ConsentedAll || status === ConsentCheckStatus.ConsentedBehavioralOnly;
    const phGranted = status === ConsentCheckStatus.ConsentedAll || status === ConsentCheckStatus.ConsentedPhysicalOnly;
    const bhLabel = `${bhGranted ? '' : 'NO'} Behavioral Health Consent`;
    const phLabel = `${phGranted ? '' : 'NO'} Physical Health Consent`;
    return (<>
      <ConsentStatusChip
        label={bhLabel}
        consentGranted={bhGranted}
        sx={{
          marginBottom: '10px',
          marginTop: '19px',
          width: '273px'
        }} />
      <ConsentStatusChip
        label={phLabel}
        consentGranted={phGranted}
        sx={{ width: '273px' }} />
    </>);
  }
  return null;
};

interface FoundPatientProps extends ConsentProps {
  patientDetails: PatientDetails | null
}

const FoundPatientProfile: React.FC<FoundPatientProps> = ({ status, patientDetails }) => {


  const { name, dob } = useMemo(() => {

    if (patientDetails) {
      const { name, dob } = patientDetails;
      return { name, dob };
    }
    return { name: undefined, dob: undefined };
  }, [patientDetails]);

  const consentGranted: boolean = useMemo(() => {
    return status === ConsentCheckStatus.ConsentedAll
      || status === ConsentCheckStatus.ConsentedBehavioralOnly
      || status === ConsentCheckStatus.ConsentedPhysicalOnly;
  }, [status]);

  const statusIcon = useMemo(() => {
    return imageForCheckResult(status);
  }, [status]);

  const mainMessage = useMemo(() => {
    return messageForCheckResult(status, name);
  }, [status, name]);

  const secondaryMessage = useMemo(() => {
    if (consentGranted && dob) {
      return (<Typography variant="body1" align="center">{`Birth: ${dob}`}</Typography>);
    }
  }, [consentGranted, dob]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      <Box sx={{ overflowY: 'visible', position: 'relative' }} >
        <img src={studentPlaceholderSvg} alt='loading' />
        {!consentGranted &&
          <Box sx={{ position: 'absolute', top: -10, right: -20 }} >
            <img src={statusIcon} alt='found' />
          </Box>
        }
      </Box>
      <Box sx={{ mt: 2, minHeight: 50 }}>
        {mainMessage}
        {secondaryMessage}
      </Box>
      <ConsentChips status={status} />
    </Box>);
};


interface LookupPatientProps {
}

const initialValues = () => ({
  firstName: '',
  lastName: '',
  dob: null
});

const getTomorrow = (dateOnly = false) => {
  let d = new Date();
  d.setDate(d.getDate() + 1);
  return dateOnly ? new Date(d.toDateString()) : d;
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().trim(),
  lastName: Yup.string().trim(),
  dob: Yup.date().max(getTomorrow(), 'Values in the future are not allowed').typeError('Invalid date format').nullable()
}).test('at-least-two-values-or-id', 'You must provide at least two values if student id not provided', function (value) {
  let count = 0;
  if (value) {
    typedKeys(value).forEach((key) => {
      if (value[key] !== null && value[key] !== undefined && value[key] !== '') {
        count += 1;
      }
    });
  }

  const isValid = count >= 2;

  return isValid || this.createError({
    path: ' ',
    message: 'You must provide at least two values, or the student\'s ID'
  });
});


interface LookupPatientDefaultState {
  checkResult: ConsentCheckStatus
}

const defaults: LookupPatientDefaultState = {
  checkResult: ConsentCheckStatus.Unknown
};

interface PatientDetails {
  name?: String | undefined
  dob?: String | undefined
}

const LookupPatient: React.FC<LookupPatientProps> = () => {

  const { userInfo, getAccessToken } = useUser();

  const [checkResult, setCheckResult] = useState(defaults.checkResult);
  const [patientDetails, setPatientDetails] = useState<PatientDetails | null>(null);
  const { postNewAlert } = useAlert();

  const [openTC, setOpenTC] = React.useState(false);
  const [isTCAgreed, setTCAgreed] = React.useState(false);

  const handleTCDialog = () => {
    setOpenTC(true);
  };

  useEffect(() => {
    console.log("user test",userInfo?.tcAgreed)
    if (!userInfo?.tcAgreed && userInfo?.tcAgreed !== undefined) {
      handleTCDialog()
    }
  }, [userInfo?.tcAgreed])

  const handleSubmit = async (data: any) => {
    
    console.log('handleSubmit');
    if (!userInfo?.tcAgreed && !isTCAgreed) {
      handleTCDialog()
    }
    else {
      try {
        const response = await API.checkPatientConsent(await getAccessToken(), data.firstName, data.lastName, data.dob);
        console.log(JSON.stringify(response));
        console.log('response', response);
        const { consentSigned, consentScope, name, dob } = response;
        if (response && consentSigned) {
          let status: ConsentCheckStatus;
          if (consentScope === ConsentScope.BehavioralAndPhysical) {
            status = ConsentCheckStatus.ConsentedAll;
          } else if (consentScope === ConsentScope.PhysicalOnly) {
            status = ConsentCheckStatus.ConsentedPhysicalOnly;
          } else {
            status = ConsentCheckStatus.ConsentedBehavioralOnly;
          }
          setCheckResult(status);
        } else {
          setCheckResult(ConsentCheckStatus.OptedOut);
        }
        setPatientDetails({ name, dob });

      } catch (e: any) {
        console.error('error checking patient consent: ', e);

        if (e.code === '4003') {
          setCheckResult(ConsentCheckStatus.NotFound); // handle user not found error as no consent.
        } else if (e.code === '4009') {
          setCheckResult(ConsentCheckStatus.MultipleFound);
        } else {
          postNewAlert(`Error checking consent (${e.code})`, 'error');
          setCheckResult(ConsentCheckStatus.Error);
        }
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnMount: true
  });

  const handleReset = () => {
    formik.resetForm();
    formik.validateForm();
    setCheckResult(ConsentCheckStatus.Unknown);
  };

  return (

    <Container>
      <Dialog setTCAgreed={setTCAgreed} open={openTC} setOpen={setOpenTC} label={"Agree to Terms and Conditions"} isTermsDialog={true} />
      <form onSubmit={formik.handleSubmit}>
        <Card variant="outlined" sx={{ mt: 4, boxShadow: 1 }} >
          <Grid container sx={{ m: 0, p: 0 }} >
            <Grid item xs sx={{ backgroundColor: '#F7F8F9', p: 2 }} >
              <Typography variant="h3">Check Patient Consent</Typography>
              <TextField
                name="firstName"
                label="Student First Name"
                fullWidth
                margin="normal"
                inputProps={{ style: { textTransform: 'capitalize' } }}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
              <TextField
                name="lastName"
                label="Student Last Name"
                fullWidth
                margin="normal"
                inputProps={{ style: { textTransform: 'capitalize' } }}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
              <DatePicker
                label="Student Date of Birth"
                value={formik.values.dob}
                disableFuture={true}
                onChange={value => {
                  formik.setFieldValue('dob', value, true);
                }}
                renderInput={(params) =>
                  <TextField
                    type='text'
                    name="dob"
                    margin="normal"
                    fullWidth
                    error={formik.touched.dob && Boolean(formik.errors.dob)}
                    helperText={formik.errors.dob}
                    {...params}
                  />
                }
              />
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: 2 }}>
                {formik.isSubmitting ? <CircularProgress />
                  : (
                    <Button
                      type='submit'
                      variant='contained'
                      disableElevation
                      color='primary'
                      size='large'
                      style={{ textTransform: 'none' }}
                      disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
                    // disabled={formikSubmitDisabled(formik)}
                    >
                      Check Consent
                    </Button>
                  )}
                <Button
                  onClick={handleReset}
                  type='button'
                  variant='text'
                  size='large'
                  style={{ textTransform: 'none' }} >
                  Reset
                </Button>
              </Box>
              <Divider orientation="horizontal" flexItem sx={{ mt: 4, mb: 2 }} />
              <Typography variant="body1">* Search with a minimum of 2 fields. Please note that results are based on how parent/guardians enters information into the consent form.</Typography>
            </Grid>

            <Grid item xs sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
              <FoundPatientProfile status={checkResult} patientDetails={patientDetails} />
            </Grid>
          </Grid>
        </Card>
      </form>
      <Box sx={{ mt: 5, textAlign: 'center' }}>
        <Typography variant="caption">If you encounter any issues with the platform, please reach out to our customer service at 516-207-7955 or schoolsupport@pmpediatrics.com</Typography>
      </Box>
    </Container>
  );
};

export default LookupPatient;
