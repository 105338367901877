import * as React from "react";
import { FormikContext, useFormik } from 'formik';
import Button from "../components/Button";
import * as Yup from 'yup';
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Dispatch, SetStateAction } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useUser } from "../contexts/UserContext";
import { API } from "../api";

const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    "& .MuiDialogTitle-root": {
        paddingLeft: theme.spacing(4),
        paddingTop: theme.spacing(4),
    },
    "& .MuiDialogActions-root": {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
}));

export interface DialogTitleProps {
    children?: React.ReactNode;
    onClose: () => void;
}

const StyledDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{fontSize:18}}  {...other} >
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

interface DialogProps {
    label: String;
    content?: String;
    onConfirm?: (event: any) => void;
    isTermsDialog?: boolean;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setTCAgreed: Dispatch<SetStateAction<boolean>>;
}

const TERMS_OF_USE_URL: string = "https://pmpediatriccare.com/account-security-and-acceptable-use-policy/"

const initialValues = () => ({
    initiatorTerms: false,
    accountTerms: false,
    businessTerms: false
    });

const validationSchema = Yup.object().shape({
    initiatorTerms: Yup.boolean().oneOf([true], 'Must Accept Account Security and Acceptable Use Policy'),
    accountTerms: Yup.boolean().oneOf([true], 'Must Accept Privacy Policy'),
    businessTerms: Yup.boolean().oneOf([true], 'Must Accept PM School Health Policy')
});
  

const CustomDialog: React.FC<DialogProps> = ({
    label,
    content,
    onConfirm,
    isTermsDialog,
    open,
    setOpen,
    setTCAgreed
}) => {
    const handleClose = () => {
        setOpen(false);
    };

    // const handlePropsConfirm: React.MouseEventHandler<
    //     HTMLButtonElement | HTMLAnchorElement
    // > = e => {
    //     onConfirm?.(e);
    //     handleClose();
    // };

    const { getAccessToken } = useUser();

    const handleTCSubmit = async (data: any) => {     
      try {
        const response = await API.agreeTerms(await getAccessToken(), formik.isValid);
        console.log(JSON.stringify(response));
        setTCAgreed(true)
      } catch (e: any) {
        console.error('error updating user terms agreement: ', e);
      }
      handleClose();
    };

    const policyText = (
        <Typography variant="body1">
            I acknowledge and affirm that I have reviewed the Initiator Guide for
            School Telemedicine Software and agree to abide by the{" "}
            <a href={TERMS_OF_USE_URL} target='_blank' rel='noopener noreferrer'>Account Security and Acceptable Use Policy</a>{" "}
            stated therein.
        </Typography>
    );

    const policies = { 
        initiatorTerms: {
            description: policyText,
            toggle: false,
            name: "initiatorTerms"
        },
        accountTerms: {
            description: (
                <Typography>
                    I agree to maintain the privacy and security of my account(s) and to
                    notify PM School Health immediately if any of my accounts become
                    compromised.
                </Typography>
            ),
            toggle: false,
            name: "accountTerms"
        },
        businessTerms: {
            description: (
                <Typography>
                    I will only access my account(s) for business-related purposes and in
                    compliance with applicable law and PM School Health policies.
                </Typography>
            ),
            toggle: false,
            name: "businessTerms"
        },
    };

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: validationSchema,
        onSubmit: handleTCSubmit,
        enableReinitialize: true,
        validateOnMount: true
    });

    type keyOfPolicies = keyof typeof policies;

    const termsContent = (
        <div className="terms-content">
            {Object.keys(policies).map((key: any) => {
                return (
                    <div key={key}>
                        {console.log(" formik test", formik.values[key as keyOfPolicies])}
                        <FormControlLabel
                            style={{ display: "table" }}
                            control={
                                <div style={{ display: "table-cell" }}>
                                    <Checkbox
                                        checked={formik.values[key as keyOfPolicies]}
                                        required
                                        name={policies[key as keyOfPolicies].name}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                            }
                            name={policies[key as keyOfPolicies].name}
                            label={policies[key as keyOfPolicies].description}
                        />
                    </div>
                );
            })}
        </div>
    );

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>      
            <StyledDialog onClose={handleClose} open={open} maxWidth={"md"}>
                <StyledDialogTitle onClose={handleClose}>
                    {label}
                </StyledDialogTitle>
                <DialogContent>
                    {isTermsDialog ? (
                        termsContent
                    ) : (
                        <Typography gutterBottom>{content}</Typography>
                    )}
                </DialogContent>
                <DialogActions style={{ justifyContent: "space-between" }}>
                    <Button
                        onClick={handleClose}
                        type="button"
                        variant="text"
                        size="large"
                        style={{ textTransform: "none" }}
                    >
                        Cancel
                    </Button>

                    <Button
                        type="submit"
                        variant="contained"
                        disableElevation
                        color="primary"
                        onClick={handleTCSubmit}
                        size="large"
                        style={{ textTransform: "none" }}
                        disabled = { !(formik.isValid) }
                    >
                        Submit
                    </Button>
                </DialogActions>
            </StyledDialog>
            </form>
        </div>
    );
};

export default CustomDialog;
