/* eslint-disable @typescript-eslint/no-use-before-define */
import './App.css';
import { useEffect } from 'react';
import { AlertContextProvider } from './contexts/AlertContext';
import AppContainer from './pages/AppContainer';
import { ThemeProvider } from '@mui/material/styles';
import { Route, Switch } from 'react-router';
import { theme } from './styles/theme';
import AnonymousContainer from './pages/AnonymousContainer';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { UserContextProvider } from './contexts/UserContext';
import LoginHelper from './pages/LoginHelper';
import Loading from './components/Loading';
import CssBaseline from '@mui/material/CssBaseline';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';


const AUTH0_DOMAIN: string = process.env.REACT_APP_AUTH0_DOMAIN!;
const AUTH0_CLIENT_ID: string = process.env.REACT_APP_AUTH0_CLIENT_ID!;
const AUTH0_AUDIENCE: string = process.env.REACT_APP_AUTH0_AUDIENCE!;

function App(): JSX.Element {
  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      cacheLocation='localstorage'
      audience={AUTH0_AUDIENCE}
      useRefreshTokens
      scope='openid'
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <AuthDecider />
          </LocalizationProvider>
      </ThemeProvider>
    </Auth0Provider>
  );
}

const AuthDecider = () => {
  const { isAuthenticated, isLoading, error, logout } = useAuth0();

  useEffect(() => {
    if (error) {
      logout();
    }
  }, [error, logout]);

  if (isLoading) return <Loading />;

  if (isAuthenticated) {
    return (
      <AlertContextProvider>
        <UserContextProvider>
          <AppContainer />
        </UserContextProvider>
      </AlertContextProvider>
    );
  } else {
    return (
      <UserContextProvider>
        <Switch>
          <Route path={['/version']}>
            <AnonymousContainer />
          </Route>
          <Route>
            <LoginHelper />
          </Route>
        </Switch>
      </UserContextProvider>
    );
  }
};

export default App;
