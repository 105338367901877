import React, { createContext, useContext, useState } from 'react';
import type { AlertColor } from  '@mui/material';

interface AlertContextProviderProps {
  message: string | null
  severity: AlertColor  
  postNewAlert: (message: string, severity: AlertColor) => void
  cleanupAlert: () => void
}

export const AlertContext = createContext<AlertContextProviderProps>({
  message: null,
  severity: 'info',
  postNewAlert: () => {},
  cleanupAlert: () => {}
});

export const useAlert = (): AlertContextProviderProps => useContext(AlertContext);

interface AlertContextProps {
  children: any
}

export function AlertContextProvider(props: AlertContextProps): JSX.Element {
  const [message, setMessage] = useState<string | null>(null);
  const [severity, setSeverity] = useState<AlertColor>('info');
  const { children } = props;

  const onPostNewAlert = (msg: string, severity: AlertColor) => {
    setMessage(msg);
    setSeverity(severity)
  };

  return (
    <AlertContext.Provider value={{
      message,
      severity,
      postNewAlert: (msg: string, severity: AlertColor) => onPostNewAlert(msg, severity),
      cleanupAlert: () => { setMessage(null); }
    }}
    >
      {children}
    </AlertContext.Provider>
  );
}
