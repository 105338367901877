// import { createMuiTheme } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { typography } from './typography';
import { paletteOpts } from './color';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    accent: PaletteColor;
    border: PaletteColor;
    placeholder: PaletteColor;
  }
  interface PaletteOptions {
    accent: PaletteColorOptions;
    border: PaletteColorOptions;
    placeholder: PaletteColorOptions;
  }
}

export const theme = createTheme({
  palette: paletteOpts,
  typography: typography
});
