import { Check, Close } from '@mui/icons-material';
import { Theme } from '@mui/material';
import Chip from '@mui/material/Chip'
import { SxProps } from '@mui/system';


interface Props {
  label: String
  consentGranted: boolean
  sx: SxProps<Theme> | undefined
}

const ContentStatusChip: React.FC<Props> = ({
  label,
  consentGranted,
  sx
}) => {
  const backgroundColor = consentGranted ? '#C8F7F8' : '#FFDCDC';
  const iconColor = consentGranted ? '#22BABB' : '#EB5757';

  return (
    <Chip label={label} icon={ consentGranted ? <Check /> : <Close />} sx={{
      ...sx,
      backgroundColor,
      '& .MuiChip-icon': {
        color: iconColor,
        fontSize: '1.25rem'
      }
    }} />
  );
};

export default ContentStatusChip;