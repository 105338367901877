import { styled } from '@mui/material';
import loading from '../assets/loading.svg';

const StyledLoading = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  flexGrow: 1
}));

const Loading = (): JSX.Element => {
  return (
    <StyledLoading>
      <img src={loading} alt='loading' />
    </StyledLoading>
  );
};

export default Loading;
