import { Theme, styled } from '@mui/material';
import BaseButton, { ButtonProps } from '@mui/material/Button';

interface ButtonStyleProps {
  size?: 'medium' | 'large' | 'small'
  variant?: 'text' | 'outlined' | 'contained'
}

const StyledBaseButton = styled(BaseButton)(({ theme }) => ({
  root: {
    height: 40,
    borderRadius: '20px',
    textTransform: 'none',
    minWidth: 'auto'
  },
  containedPrimary: {
    backgroundColor: theme.palette.secondary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#25919A',
      color: '#FFFFFF'
    },
    '&:disabled': {
      color: '#FFFFFF',
      backgroundColor: '#D6F0F2'
    }
  },
  containedSecondary: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: '#BBE7EB',
      color: theme.palette.secondary.main
    },
    '&:disabled': {
      color: '#BBE7EB',
      backgroundColor: '#E7F8FA'
    }
  },
  textPrimary: {
    backgroundColor: '#FFFFFF',
    color: theme.palette.secondary.main,
    '&:hover': {
      color: '#25919A',
      backgroundColor: '#FFFFFF'
    },
    '&:disabled': {
      color: '#BBE7EB',
      backgroundColor: '#FFFFFF'
    }
  }
}));

interface MyButtonProps extends ButtonProps {
  type?: 'button' | 'submit' | 'reset'
  color?: 'inherit' | 'primary' | 'secondary'
  variant?: 'text' | 'outlined' | 'contained'
  size?: 'medium' | 'large' | 'small'
  disabled?: boolean
  disableElevation?: boolean
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const Button: React.FC<MyButtonProps> = ({
  children,
  type = 'button',
  disabled = false,
  variant = 'contained',
  color = 'primary',
  size = 'large',
  disableElevation = true,
  ...restProps
}) => {
  return (
    <StyledBaseButton
      type={type}
      disabled={disabled}
      variant={variant}
      size={size}
      color={color}
      disableElevation={disableElevation}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    >
      {children}
    </StyledBaseButton>
  );
};

export default Button;
