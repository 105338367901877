export enum ErrorCode {
  // 4xx
  NoRequestBody = '4001',
  InvalidRequestBody = '4002',
  UserNotFound = '4003',
  UserAlreadyExistsEmail = '4004',
  UserAlreadyExistsAuth0 = '4005',
  ValidationError = '4006',
  NoRequestParams = '4007',
  InvalidQueryParams = '4008',
  InvalidPathParams = '4009',
  InvalidRole = '4010',
  UnknownMimeType = '4011',
  IllegalMimeType = '4012',
  ReferralNotFound = '4013',
  ProfileAlreadyPublished = '4014',
  ProfileNotComplete = '4015',
  UnexpectedProfileId = '4016',
  DealDoesNotExist = '4017',
  ReferralDoesNotExist = '4018',
  ReferralClosed = '4019',
  NoWriteAccess = '4020',
  ProfileNotFound = '4021',
  ProfileNotYetPublished = '4022',
  InvalidMatchAlgorithmInput = '4023',
  NoReadAccess = '4024',
  FinancialProjectionsInvalid = '4025',
  // 5xx
  InternalError = '5001'
}
