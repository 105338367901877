import { Theme, styled } from '@mui/material';
import mainLogo from '../assets/logo.svg';

type LogoNameStylesProps = {
  clickable: boolean;
};
interface LogoNameProps {
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
}

const LogoImage = styled('img')(({ theme }) => ({
  width: '121px',
  height: '32px'
}));

const LogoName: React.FC<LogoNameProps> = ({
  onClick
}) => {
  return (<LogoImage src={mainLogo} alt="PM Pediatrics"/>);
};

export default LogoName;

