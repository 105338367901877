import React from 'react';
import { Menu, MenuItem, styled, Avatar } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Button from './Button';
import { LogoutOptions } from '@auth0/auth0-react';

const AccountButton = styled('button')(({ theme }) => ({
  height: 40,
  width: 40,
  border: 'none',
  margin: '0 10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  background: '#FFFFFF',
  padding: '0 20px 0 0',
  '&:hover': {
    cursor: 'pointer'
  }
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  minWidth: 272,
  padding: `0px ${theme.spacing(2)}px`,
  border: '1px solid #D0D6DA',
  borderRadius: '4px',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)'
}));

const LogoutMenuItem = styled(MenuItem)(({ theme }) => ({
  '&:hover': {
    cursor: 'auto',
    backgroundColor: 'transparent'
  }
}));

const LogoutButton = styled(Button)(({ theme }) => ({
  width: '100%',
  padding: 0,
  justifyContent: 'flex-start'
}));

interface ProfileButtonProps {
  onLogout: (options?: LogoutOptions) => void
}

const ProfileButton: React.FC<ProfileButtonProps> = ({
  onLogout
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    onLogout();
    handleClose();
  };

  return (
    <div>
      <AccountButton type='button' onClick={handleClick}>
        <Avatar alt="avatar" />    
      </AccountButton>
      <StyledMenu
        elevation={0}
        anchorOrigin={{
          vertical: 52,
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        id='customized-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <LogoutMenuItem>
          <LogoutButton variant='text' onClick={handleLogout}>
            <ExitToAppIcon style={{ marginRight: '10px' }} />
            Log out
          </LogoutButton>
        </LogoutMenuItem>
      </StyledMenu>
    </div>
  );
};

export default ProfileButton;
