import React, { useContext, createContext, useState, useEffect, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { NurseUser, ErrorCode } from '../types/types';
import API, { ApiError, isApiError } from '../api/api';
import { useAlert } from './AlertContext';

interface UserContextProps {
  children: any
}

interface UserContextProviderProps {
  loading: boolean
  userInfo: NurseUser | null
  setUserInfo: (p: any) => void
  refreshUserInfo: () => void
  getAccessToken: () => Promise<string>
  rootRedirect: string | null
  presentLogin: () => void
  userIsSelectingAvatar: boolean
  setUserIsSelectingAvatar: (isSelecting: boolean) => void
}

export const UserContext = createContext<UserContextProviderProps>({
  userInfo: null,
  loading: false,
  setUserInfo: () => {},
  refreshUserInfo: () => {},
  getAccessToken: () => Promise.resolve(''),
  rootRedirect: null,
  presentLogin: () => {},
  userIsSelectingAvatar: false,
  setUserIsSelectingAvatar: (isSelecting: boolean) => {}
});

export const useUser = (): UserContextProviderProps => useContext(UserContext);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function UserContextProvider(props: UserContextProps) {
  const [userInfo, setUserInfo] = useState<NurseUser | null>(null);
  const { getAccessTokenSilently, isAuthenticated, logout } = useAuth0();
  const { user } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [userIsSelectingAvatar, setUserIsSelectingAvatar] = useState(false);
  const { children } = props;
  const { postNewAlert } = useAlert();
  const [rootRedirect, setRootRedirect] = useState<string | null>(null);
  
  const getAccessToken = async (): Promise<string> => {
    try {
      return await getAccessTokenSilently();
    } catch (e: unknown) {
      postNewAlert('An unexpected error occurred. Please try again later.', 'error');
      console.log(e);
      throw e; // re-throw, it can't really be handled here (?)
    }
  };

  const refreshUserInfo = async () => {
    let finishedLoading = true;
    try {
      setLoading(true);
      const userResponse = await API.getUser(await getAccessTokenSilently());
      setUserInfo(userResponse);
    } catch (error: unknown) {
      console.error(error);      
      if (isApiError(error)) {
        const apiError = error as ApiError;
        console.log('API error', JSON.stringify(error));
        throw error;
      } else {
        throw error; // re-throw anything except the specific errors we can handle above.
      }
    } finally {
      if (finishedLoading) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const getAccessTokenAndUser = async () => {
      try {
        await refreshUserInfo();
      } catch (e: unknown) {        
        postNewAlert('An error occurred refreshing user data', 'error');
        console.error('An error occurred refreshing user data: ', e);
      }
    };
    if (isAuthenticated) {
      getAccessTokenAndUser();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, isAuthenticated]);

  const presentLogin = () => {
    if (!isAuthenticated) {
      setRootRedirect('/');
    }
  };

  return (
    <UserContext.Provider value={{
      userInfo,
      loading,      
      setUserInfo: (p: any) => setUserInfo(p),
      refreshUserInfo: () => refreshUserInfo(),
      getAccessToken: () => getAccessToken(),
      rootRedirect,
      presentLogin,
      userIsSelectingAvatar,
      setUserIsSelectingAvatar
    }}
    >
      {children}
    </UserContext.Provider>
  );
}
