import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CircularProgress, IconButton, Snackbar, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Home from './Home';
import Version from './Version';
import NavBar from '../components/NavBar';
import LookupPatient from './LookupPatient';
import { Footer } from '../components/Footer';
import { useAlert } from '../contexts/AlertContext';
import ErrorView from './ErrorView';

export default function AppContainer(): JSX.Element {

  const { message: alertMessage, severity: alertSeverity, cleanupAlert } = useAlert();

  return (
    <Router>
      <NavBar />
      <Switch>
        <Route exact path='/' component={LookupPatient} />
        <Route path='/version' component={Version} />
        <Route path='/consent' component={LookupPatient} />
      </Switch>     
      <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={!!alertMessage}
          autoHideDuration={6000}
          onClose={() => { cleanupAlert(); }} 
          // message={alertMessage}
          // action={(
          //   <>
          //     <IconButton size='small' aria-label='close' color='inherit' onClick={() => { cleanupAlert(); }}>
          //       <CloseIcon fontSize='small' />
          //     </IconButton>
          //   </>
          // )}      
        >
        <Alert onClose={() => { cleanupAlert(); }} variant="filled" severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>   
      <Footer />      
    </Router>
  );
}
