/* eslint-disable @typescript-eslint/no-throw-literal */
import { CheckPatientConsentResponse, NurseUser, AgreeTermsResponse } from '../types/types';
import moment from 'moment';

const API_URL: string = process.env.REACT_APP_API_URL!;

export const InternalError: ApiError = {
  code: '500',
  message: 'Internal Service Error'
};
export interface ApiError {
  message: string,
  code: string
}

export enum ApiStatus {
  failed, success, loading
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isApiError = (error: any): boolean => (error instanceof Object && error && 'message' in error && 'code' in error);

const apiErrorToThrow = (error: any): ApiError => {
  if (isApiError(error)) {
    return error;
  } else {
    console.error('An endpoint threw and did not provide a well formed ApiError');
    return InternalError;
  }
};

class API {
  // If the user is *not* authenticated, use this to get the ent profile.
  async checkPatientConsent(auth0AccessToken: string, firstName: string, lastName: string, dob: Date): Promise<CheckPatientConsentResponse> {
    try {
      let ENDPOINT = `${API_URL}/patient/consent`;

      console.log('dob in api call', dob);

      const params = new URLSearchParams([
        ['firstName', firstName !== undefined ? firstName : ''],
        ['lastName', lastName !== undefined ? lastName : ''],
        ['dob', dob ? moment(dob).format('YYYYMMDD') : '']
      ]);

      ENDPOINT += `?${params}`;
      console.log();
      const response = await fetch(ENDPOINT, {
        method: 'GET',
        headers: { Authorization: `Bearer ${auth0AccessToken}` }
      });
  
      const json = await response.json();
  
      if (!response.ok) {
        throw apiErrorToThrow(json);
      }

      return json;
    } catch (error: unknown) {
      throw apiErrorToThrow(error);
    }
  }

  async getUser(auth0AccessToken: string): Promise<NurseUser> {
    try {
      const ENDPOINT = `${API_URL}/user/me`;

      const response = await fetch(ENDPOINT, {
        headers: {
          Authorization: `Bearer ${auth0AccessToken}`
        }
      });

      const json = await response.json();
      console.log('json response from user endpoing: ', json);

      if (!response.ok) {
        throw apiErrorToThrow(json);
      }

      const returnUser: NurseUser = {
        id: json.userId,
        name: `${json.firstName} ${json.lastName}`,
        email: json.email,
        role: json.role,
        profileId: json.profileId,
        tcAgreed: json.agreedTC
      };

      return returnUser;
    } catch (error: unknown) {
      throw apiErrorToThrow(error);
    }
  }
  
  async agreeTerms(auth0AccessToken: string, agreedTerms: boolean): Promise<AgreeTermsResponse> {
    try {
      let ENDPOINT = `${API_URL}/tc/agree`;

      const response = await fetch(ENDPOINT, {
        method: 'PUT',
        headers: { Authorization: `Bearer ${auth0AccessToken}` },
        body: JSON.stringify({
          agreedTerms
        })
      });
  
      const json = await response.json();
  
      if (!response.ok) {
        throw apiErrorToThrow(json);
      }

      return json;
    } catch (error: unknown) {
      throw apiErrorToThrow(error);
    }
  }

}

const api = new API();
export default api;
