import { ErrorCode } from './error';

export type Optional<T> = T | null;

export interface NurseUser {
  id: number
  name: string
  email: string
  profileId: number
  role: Role
  tcAgreed: boolean
}

export enum Role {
  Nurse = 'nurse'
}

export enum TypographyColor {
  initial = 'initial',
  inherit = 'inherit',
  primary = 'primary',
  secondary = 'secondary',
  textPrimary = 'textPrimary',
  textSecondary = 'textSecondary',
  error = 'error'
}

export enum Orientation {
  Horizontal = 0,
  Vertical = 1
}

export enum Shape {
  Rect = 0,
  Round = 1
}

export enum LoadingStatus {
  Idle = 'idle',
  Loading = 'loading',
  Complete = 'complete'
}

export enum ConsentScope {
  BehavioralOnly = 'Behavioral Health Only',
  PhysicalOnly = 'Physical Health Only',
  BehavioralAndPhysical = 'Both Physical and Behavioral Health'
};
export interface CheckPatientConsentResponse {
  consentSigned: boolean
  consentScope: ConsentScope
  name?: String
  dob?: String
};

export interface AgreeTermsResponse {
  tcSigned: boolean
}

export {
  ErrorCode
};
