import { TypographyStyle, SimplePaletteColorOptions } from '@mui/material/styles';
import { paletteOpts } from './color';

export type TypographyColor =
  | 'initial'
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'textPrimary'
  | 'textSecondary'
  | 'error'
  | 'placeholder';

export type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'subtitle3'
  | 'subtitle4'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'body4'
  | 'textButton'
  | 'overline'
  | 'subtext1'
  | 'subtext2'
  | 'fieldLabel'
  | 'financials'
  | 'financialsImportant'
  | 'counter'
  | 'counterSmall'
  | 'matchPercentage'
  | 'menuItem'
  | 'activeMenuItem'
  | 'tab'
  | 'activeTab'
  | 'investorStatusFilter'
  | 'investorStatusFilterSelected'
  | 'investorStatus'
  | 'statusChip'
  | 'breadcrumbs'
  | 'breadcrumbsActive'
  | 'link';

export type TypographyStylesMap = Record<TypographyVariant, TypographyStyle>;

const defaultTypographyStyle: TypographyStyle = {
  fontFamily: 'Prompt, sans-serif',
  fontSize: 16,
  fontWeight: 'normal',
  textTransform: 'none',
  whiteSpace: 'pre-wrap'
};

export const typography: TypographyStylesMap = {
  h1: {
    ...defaultTypographyStyle,
    fontSize: 36,
    fontWeight: 500
  },
  h2: {
    ...defaultTypographyStyle,
    fontSize: 24,
    fontWeight: 500
  },
  h3: {
    ...defaultTypographyStyle,
    fontSize: 20,
    fontWeight: 500
  },
  h4: {
    ...defaultTypographyStyle,
    fontSize: 18,
    fontWeight: 500
  },
  h5: {
    ...defaultTypographyStyle,
    fontSize: 16,
    fontWeight: 500
  },
  h6: {
    ...defaultTypographyStyle,
    fontSize: 14,
    fontWeight: 500
  },
  subtitle1: {
    ...defaultTypographyStyle,
    fontSize: 16,
    fontWeight: 300
  },
  subtitle2: {
    ...defaultTypographyStyle,
    fontSize: 16,
    fontWeight: 500
  },
  subtitle3: {
    ...defaultTypographyStyle,
    fontSize: 18
  },
  subtitle4: {
    ...defaultTypographyStyle,
    fontSize: 20,
    fontWeight: 300
  },
  body1: {
    ...defaultTypographyStyle,
    fontSize: 16,
    fontWeight: 300
  },
  body2: {
    ...defaultTypographyStyle,
    fontSize: 16,
    fontWeight: 500
  },
  body3: {
    ...defaultTypographyStyle,
    fontSize: 18,
    fontWeight: 300
  },
  body4: {
    ...defaultTypographyStyle,
    fontSize: 24,
    fontWeight: 300
  },
  textButton: {
    ...defaultTypographyStyle,
    fontSize: 18,
    fontWeight: 500
  },
  overline: {
    ...defaultTypographyStyle,
    fontSize: 18
  },
  subtext1: {
    ...defaultTypographyStyle,
    fontSize: 12
  },
  subtext2: {
    ...defaultTypographyStyle,
    fontSize: 14,
    fontWeight: 300
  },
  fieldLabel: {
    ...defaultTypographyStyle,
    fontSize: 14,
    fontWeight: 500
  },
  financials: {
    ...defaultTypographyStyle,
    fontSize: 20,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  financialsImportant: {
    ...defaultTypographyStyle,
    fontSize: 26,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  counter: {
    ...defaultTypographyStyle,
    fontSize: 20,
    fontWeight: 500
  },
  counterSmall: {
    ...defaultTypographyStyle,
    fontSize: 16,
    fontWeight: 500
  },
  matchPercentage: {
    ...defaultTypographyStyle,
    fontSize: 18
  },
  menuItem: {
    ...defaultTypographyStyle,
    fontSize: 16,
    fontWeight: 300
  },
  activeMenuItem: {
    ...defaultTypographyStyle,
    fontSize: 16,
    fontWeight: 500
  },
  tab: {
    ...defaultTypographyStyle,
    fontSize: 20,
    fontWeight: 300
  },
  activeTab: {
    ...defaultTypographyStyle,
    fontSize: 20,
    fontWeight: 500
  },
  investorStatusFilter: {
    ...defaultTypographyStyle,
    fontSize: 16,
    fontWeight: 300
  },
  investorStatusFilterSelected: {
    ...defaultTypographyStyle,
    fontSize: 16,
    fontWeight: 500
  },
  investorStatus: {
    ...defaultTypographyStyle,
    fontSize: 20,
    fontWeight: 500
  },
  statusChip: {
    ...defaultTypographyStyle,
    fontSize: 16
  },
  breadcrumbs: {
    ...defaultTypographyStyle,
    fontWeight: 400,
    lineHeight: '19.6px',
    fontSize: 14
  },
  breadcrumbsActive: {
    ...defaultTypographyStyle,
    fontWeight: 400,
    lineHeight: '19.6px',
    fontSize: 14
  },
  link: {
    textDecorationLine: 'underline',
    fontSize: 16,
    fontWeight: 300
  }
};

export type TypographyColorMap = Record<TypographyColor, string>;

export const typographyColorMap: TypographyColorMap = {
  initial: 'initial',
  inherit: 'inherit',
  primary: paletteOpts.primary.main,
  secondary: paletteOpts.secondary.main,
  textPrimary: paletteOpts.text.primary,
  textSecondary: paletteOpts.text.secondary,
  error: paletteOpts.error.main,
  placeholder: paletteOpts.placeholder.main
};
