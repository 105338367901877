import { Box, Container, Theme, styled } from '@mui/material';
import { StyledText } from './StyledText';

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  backgroundColor: theme.palette.background.default,
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
    flexDirection: 'column',
    justifyContent: 'center'
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(3),
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}));

const StyledLinksContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  flex: '1 1 70%',
  '& div:nth-child(2)': {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

const StyledLogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  opacity: 0.3
}));

const PRIVACY_POLICY_URL: string = `${process.env.REACT_APP_LANDING_PAGE_URL_BASE!}privacy-policy`;
const TERMS_OF_USE_URL: string = `${process.env.REACT_APP_LANDING_PAGE_URL_BASE!}terms-of-use`;


export const Footer = (): JSX.Element => {
  return (
    <StyledContainer maxWidth='xl'>
      {/* <Box className={styles.linksContainer}>
        <StyledText variant='body3' color='textSecondary' component='div'>
          <Link href={PRIVACY_POLICY_URL} color='inherit'>
            Privacy Policy
          </Link>
        </StyledText>
        <StyledText variant='body3' color='textSecondary' component='div'>
          <Link href={TERMS_OF_USE_URL} color='inherit'>
            Terms of Use
          </Link>
        </StyledText>
      </Box>
      <Box className={styles.logoContainer}>
        
      </Box> */}
    </StyledContainer>
  );
};
