import { useHistory } from 'react-router';
import { AppBar, Toolbar, Box, Container, styled, Typography, Skeleton } from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import LogoName from './LogoName';
import { useUser } from '../contexts/UserContext';
import ProfileButton from './ProfileButton';
import { useAuth0 } from '@auth0/auth0-react';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
  background: '#FFFFFF',
  boxShadow: 'none',
  borderBottom: '1px #DAD9DF solid'
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3)
}));

const NavBar = (): JSX.Element => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window
  });
  const { userInfo } = useUser();
  const { logout } = useAuth0();

  return (
    <Container sx={{ margin: 0, width: '100%', maxWidth: '100%' }} disableGutters>
      <StyledAppBar elevation={trigger ? 4 : 0}>
        <StyledToolbar disableGutters>          
        { ( 
        [
            <Box display='flex' alignItems='center' key="navBarBox">
            { (
            [                          
            <LogoName key='logo'/>//,
            ]
            )}
            </Box>,
            <Box display='flex' flexDirection='row' key='profileBox'>
            { (
            [    
              <Box display='flex' flexDirection='column' alignItems='flex-end' justifyContent='center' key='profileInfo'>
                { (
                [   
                  <Typography variant="inherit" color='black' key='userName'>{userInfo?.name ?? <Skeleton width={100}/>}</Typography>,
                  // <Typography variant="body1" color='gray' key='userRole'>{userInfo ? 'School Nurse' : <Skeleton width={100}/>}</Typography>
                ]
                )} 
              </Box>
              ,
              <ProfileButton onLogout={logout} key='not-1' />         
            ]
            )}            
            </Box>
        ] 
        ) }
        </StyledToolbar>
      </StyledAppBar>
      <Toolbar />
    </Container>
  );
};

export default NavBar;
