import { styled } from '@mui/material';
import Dialog from '../components/Dialog';
import Button from '@mui/material/Button';
import React from 'react';
import { API } from '../api';
import { useUser } from '../contexts/UserContext';

const StyledDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  flexGrow: 1
}));

export default function Version(): JSX.Element {

  return (
    <StyledDiv>
      <p>
        Env:
        {process.env.REACT_APP_ENV}
      </p>
      <p>
        Hash:
        {process.env.REACT_APP_SHA}
      </p>
      <p>
        Version:
        {process.env.REACT_APP_VERSION}
      </p>
    </StyledDiv>
  );
}
